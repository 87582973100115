import React, { useState, useEffect } from 'react';
import { Table, Button, Modal, Form, Input, Select, DatePicker, TimePicker, message } from 'antd';
import axios from 'axios';

const { Option } = Select;

const API_BASE_URL = 'https://api.nillq.com'; // Define base URL
const API_ENDPOINTS = {
  associatedDoctors: `${API_BASE_URL}/api/hospital/associated-doctors`,
  appointments: `${API_BASE_URL}/api/appointments/hospital`,
  doctorName: `${API_BASE_URL}/api/doctor/name`,
  addresses: `${API_BASE_URL}/api/addresses`,
  createAppointment: `${API_BASE_URL}/api/appointments/create`,
  cancelAppointment: (appointmentId) => `${API_BASE_URL}/api/appointments/${appointmentId}/cancel`
};

const AppointmentsManagement = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();
  const [appointments, setAppointments] = useState([]);
  const [addresses, setAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [doctorNames, setDoctorNames] = useState({});
  const [associatedDoctors, setAssociatedDoctors] = useState([]); // State for associated doctors
  const [selectedDoctor, setSelectedDoctor] = useState(null); // State for selected doctor
  const [isCancelModalVisible, setIsCancelModalVisible] = useState(false);
  const [cancelReason, setCancelReason] = useState('');
  const [selectedAppointment, setSelectedAppointment] = useState(null);

  useEffect(() => {
    fetchAssociatedDoctors(); // Fetch associated doctors instead of joined doctors
    const storedAddresses = JSON.parse(localStorage.getItem('addresses'));
    if (storedAddresses) {
      setAddresses(storedAddresses);
      fetchAppointments(storedAddresses);
    } else {
      fetchAddresses().then(fetchedAddresses => {
        localStorage.setItem('addresses', JSON.stringify(fetchedAddresses));
        fetchAppointments(fetchedAddresses);
      });
    }
  }, []);

  const fetchAssociatedDoctors = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const hospitalId = localStorage.getItem('hospitalId');
  
      // Debugging hospitalId
      console.log('Hospital ID:', hospitalId);
  
      if (!hospitalId) {
        message.error('Hospital ID is missing.');
        return;
      }
  
      // Update the query parameter to hospitalId
      const response = await axios.get(`${API_ENDPOINTS.associatedDoctors}?hospitalId=${hospitalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Fetched associated doctors:', response.data); // Debugging
      setAssociatedDoctors(response.data);
    } catch (error) {
      console.error('Error fetching associated doctors:', error);
      message.error('Failed to fetch associated doctors.');
    }
  };
  

  const fetchAppointments = async (addresses) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const hospitalId = localStorage.getItem('hospitalId');
      const response = await axios.get(`${API_ENDPOINTS.appointments}?hospital_id=${hospitalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      console.log('Fetched appointments:', response.data); // Debugging
      const transformedData = await transformAppointments(response.data, addresses);
      setAppointments(transformedData);
    } catch (error) {
      console.error('Error fetching appointments:', error);
      message.error('Failed to fetch appointments.');
    }
  };

  const fetchDoctorName = async (doctorId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get(`${API_ENDPOINTS.doctorName}?doctor_id=${doctorId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      return response.data.name;
    } catch (error) {
      console.error('Error fetching doctor name:', error);
      return 'Unknown Doctor';
    }
  };

  const transformAppointments = async (data, addresses) => {
    const doctorIds = [...new Set(data.map(appt => appt.doctorId).filter(id => id !== null))];
    const doctorNamesMap = {};

    for (const id of doctorIds) {
      if (!doctorNames[id]) {
        doctorNamesMap[id] = await fetchDoctorName(id);
      }
    }

    setDoctorNames(doctorNamesMap);

    return data.map((appointment) => {
      const address = addresses.find((addr) => addr._id === appointment.addressId);
      const timeStart = new Date(`1970-01-01T${appointment.timeStart}:00`);
      const timeEnd = new Date(`1970-01-01T${appointment.timeEnd}:00`);
      const timeDifference = (timeEnd - timeStart) / 60000; // Time difference in minutes
      const timePerPatient = timeDifference / appointment.numberOfTokens;

      return {
        key: appointment._id,
        date: new Date(appointment.date).toLocaleDateString('en-US', {
          weekday: 'long',
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        }),
        timeStart: appointment.timeStart,
        timeEnd: appointment.timeEnd,
        timePerPatient: timePerPatient.toFixed(2),
        address: address
          ? `${address.location}, ${address.address_line1}, ${address.city}, ${address.state}, ${address.postal_code}`
          : 'Address not found',
        numberOfTokens: appointment.numberOfTokens,
        remainingTokens: appointment.remainingTokens,
        doctorId: appointment.doctorId,
        isComplete: appointment.isComplete
      };
    }).filter(appointment => !appointment.isComplete); // Only show incomplete appointments
  };

  const fetchAddresses = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const hospitalId = localStorage.getItem('hospitalId');
      const response = await axios.get(`${API_ENDPOINTS.addresses}?hospital_id=${hospitalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      setAddresses(response.data);
      console.log('Fetched addresses:', response.data); // Debugging
      return response.data;
    } catch (error) {
      console.error('Error fetching addresses:', error);
      message.error('Failed to fetch addresses.');
      return [];
    }
  };

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = async () => {
    try {
      const values = await form.validateFields();
      const token = localStorage.getItem('jwtToken');
      const hospitalId = localStorage.getItem('hospitalId');
      let addressId = selectedAddress;

      if (selectedAddress === 'add_new') {
        const newAddressDetails = {
          hospital_id: hospitalId,
          location: values.newAddressDetails.location || '',
          address_line1: values.newAddressDetails.address_line1 || '',
          address_line2: values.newAddressDetails.address_line2 || '',
          city: values.newAddressDetails.city || '',
          state: values.newAddressDetails.state || '',
          postal_code: values.newAddressDetails.postal_code || '',
          country: 'India', // Assuming India is default
        };

        // Add new address first
        const newAddressResponse = await axios.post(API_ENDPOINTS.addresses, newAddressDetails, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        addressId = newAddressResponse.data.addressId;
        const updatedAddresses = await fetchAddresses(); // Refresh addresses after adding a new one
        localStorage.setItem('addresses', JSON.stringify(updatedAddresses));
      }

      const appointmentData = {
        doctorId: selectedDoctor, // Add the selected doctor ID
        hospitalId,
        date: values.date.format('YYYY-MM-DD'),
        timeStart: values.timeStart.format('HH:mm'),
        timeEnd: values.timeEnd.format('HH:mm'),
        addressId,
        numberOfTokens: values.numberOfTokens,
      };

      await axios.post(API_ENDPOINTS.createAppointment, appointmentData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      message.success('Appointment created successfully');
      const updatedAddresses = await fetchAddresses(); // Refresh addresses for appointments transformation
      fetchAppointments(updatedAddresses);
      setIsModalVisible(false);
      form.resetFields();
    } catch (error) {
      console.error('Error creating appointment:', error);
      message.error('Failed to create appointment.');
    }
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    form.resetFields();
  };

  const handleCancelAppointment = async () => {
    if (!selectedAppointment) return;

    try {
      const token = localStorage.getItem('jwtToken');
      await axios.put(API_ENDPOINTS.cancelAppointment(selectedAppointment.key), {
        reason: cancelReason,
      }, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      message.success('Appointment canceled successfully');
      const updatedAddresses = await fetchAddresses();
      fetchAppointments(updatedAddresses);
      setIsCancelModalVisible(false);
    } catch (error) {
      console.error('Error canceling appointment:', error);
      message.error('Failed to cancel appointment.');
    }
  };

  const handleCancelReasonChange = (e) => {
    setCancelReason(e.target.value);
  };

  const handleCancelModal = (appointment) => {
    setSelectedAppointment(appointment);
    setIsCancelModalVisible(true);
  };

  // Disable past dates in DatePicker
  const disabledDate = (current) => {
    return current && current < new Date().setHours(0, 0, 0, 0);
  };

  return (
    <div className="p-6">
      <h3 className="text-2xl font-semibold mb-4">Appointments Management</h3>

      {/* Create Appointment Section */}
      <div className="mb-6">
        <Button type="primary" onClick={showModal}>
          Create Appointment
        </Button>
        <Modal
          title="Create Appointment"
          visible={isModalVisible}
          onOk={handleOk}
          onCancel={handleCancel}
          okText="Create"
          cancelText="Cancel"
        >
          <Form form={form} layout="vertical">
            <Form.Item
              name="date"
              label="Date"
              rules={[{ required: true, message: 'Please select a date' }]}
            >
              <DatePicker className="w-full" disabledDate={disabledDate} />
            </Form.Item>
            <Form.Item
              name="timeStart"
              label="Start Time"
              rules={[{ required: true, message: 'Please select a start time' }]}
            >
              <TimePicker use12Hours format="h:mm a" minuteStep={15} className="w-full" />
            </Form.Item>
            <Form.Item
              name="timeEnd"
              label="End Time"
              rules={[{ required: true, message: 'Please select an end time' }]}
            >
              <TimePicker use12Hours format="h:mm a" minuteStep={15} className="w-full" />
            </Form.Item>
            <Form.Item
              name="doctor"
              label="Select Doctor"
              rules={[{ required: true, message: 'Please select a doctor' }]}
            >
              <Select placeholder="Select doctor" onChange={setSelectedDoctor}>
                {associatedDoctors.map((doctor) => (
                  <Option key={doctor._id} value={doctor._id}>
                    {doctor.name}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item
              name="address"
              label="Address"
              rules={[{ required: true, message: 'Please select or enter an address' }]}
            >
              <Select
                placeholder="Select or type address"
                onChange={(value) => setSelectedAddress(value)}
              >
                {addresses.map((addr) => (
                  <Option key={addr._id} value={addr._id}>
                    {`${addr.location}, ${addr.address_line1}, ${addr.city}, ${addr.state}, ${addr.postal_code}`}
                  </Option>
                ))}
                <Option value="add_new">Add New Address</Option>
              </Select>
            </Form.Item>
            {selectedAddress === 'add_new' && (
              <>
                <Form.Item
                  name={['newAddressDetails', 'location']}
                  label="Location"
                  rules={[{ required: true, message: 'Please enter the location' }]}
                >
                  <Input placeholder="Location" />
                </Form.Item>
                <Form.Item
                  name={['newAddressDetails', 'address_line1']}
                  label="Address Line 1"
                  rules={[{ required: true, message: 'Please enter the address line 1' }]}
                >
                  <Input placeholder="Address Line 1" />
                </Form.Item>
                <Form.Item
                  name={['newAddressDetails', 'address_line2']}
                  label="Address Line 2"
                >
                  <Input placeholder="Address Line 2" />
                </Form.Item>
                <Form.Item
                  name={['newAddressDetails', 'city']}
                  label="City"
                  rules={[{ required: true, message: 'Please enter the city' }]}
                >
                  <Input placeholder="City" />
                </Form.Item>
                <Form.Item
                  name={['newAddressDetails', 'state']}
                  label="State"
                  rules={[{ required: true, message: 'Please enter the state' }]}
                >
                  <Input placeholder="State" />
                </Form.Item>
                <Form.Item
                  name={['newAddressDetails', 'postal_code']}
                  label="Postal Code"
                  rules={[{ required: true, message: 'Please enter the postal code' }]}
                >
                  <Input placeholder="Postal Code" />
                </Form.Item>
              </>
            )}
            <Form.Item
              name="numberOfTokens"
              label="Number of Tokens"
              rules={[{ required: true, message: 'Please enter the number of tokens' }]}
            >
              <Input type="number" />
            </Form.Item>
          </Form>
        </Modal>
      </div>

      {/* Appointments Table Section */}
      <div>
        <h4 className="text-lg font-semibold mb-2">Appointments Created by You</h4>
        <Table
          columns={[
            {
              title: 'Date',
              dataIndex: 'date',
              key: 'date',
            },
            {
              title: 'Time Start',
              dataIndex: 'timeStart',
              key: 'timeStart',
            },
            {
              title: 'Time End',
              dataIndex: 'timeEnd',
              key: 'timeEnd',
            },
            {
              title: 'Time Per Patient (mins)',
              dataIndex: 'timePerPatient',
              key: 'timePerPatient',
            },
            {
              title: 'Address',
              dataIndex: 'address',
              key: 'address',
            },
            {
              title: 'Number of Tokens',
              dataIndex: 'numberOfTokens',
              key: 'numberOfTokens',
            },
            {
              title: 'Remaining Tokens',
              dataIndex: 'remainingTokens',
              key: 'remainingTokens',
            },
            {
              title: 'Actions',
              key: 'actions',
              render: (_, record) => (
                <Button type="link" onClick={() => handleCancelModal(record)}>
                  Cancel
                </Button>
              ),
            },
          ]}
          dataSource={appointments.filter(appt => !appt.doctorId)}
          pagination={{ pageSize: 5 }}
        />

        {/* Appointments by Doctor */}
        {Object.keys(doctorNames).map(doctorId => (
          <div key={doctorId} className="mt-8">
            <h4 className="text-lg font-semibold mb-2">Appointments Managed by {doctorNames[doctorId]}</h4>
            <Table
              columns={[
                {
                  title: 'Date',
                  dataIndex: 'date',
                  key: 'date',
                },
                {
                  title: 'Time Start',
                  dataIndex: 'timeStart',
                  key: 'timeStart',
                },
                {
                  title: 'Time End',
                  dataIndex: 'timeEnd',
                  key: 'timeEnd',
                },
                {
                  title: 'Time Per Patient (mins)',
                  dataIndex: 'timePerPatient',
                  key: 'timePerPatient',
                },
                {
                  title: 'Address',
                  dataIndex: 'address',
                  key: 'address',
                },
                {
                  title: 'Number of Tokens',
                  dataIndex: 'numberOfTokens',
                  key: 'numberOfTokens',
                },
                {
                  title: 'Remaining Tokens',
                  dataIndex: 'remainingTokens',
                  key: 'remainingTokens',
                },
                {
                  title: 'Actions',
                  key: 'actions',
                  render: (_, record) => (
                    <Button type="link" onClick={() => handleCancelModal(record)}>
                      Cancel
                    </Button>
                  ),
                },
              ]}
              dataSource={appointments.filter(appt => appt.doctorId === doctorId)}
              pagination={{ pageSize: 5 }}
            />
          </div>
        ))}
      </div>

      {/* Cancel Appointment Modal */}
      <Modal
        title="Cancel Appointment"
        visible={isCancelModalVisible}
        onOk={handleCancelAppointment}
        onCancel={() => setIsCancelModalVisible(false)}
        okText="Cancel Appointment"
        cancelText="Back"
      >
        <Form layout="vertical">
          <Form.Item
            label="Reason for Cancellation"
            rules={[{ required: true, message: 'Please provide a reason for cancellation' }]}
          >
            <Input.TextArea rows={4} value={cancelReason} onChange={handleCancelReasonChange} />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default AppointmentsManagement;
