import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment';
import { message, List, Card } from 'antd';

const DashboardHome = () => {
  const [appointments, setAppointments] = useState([]);

  useEffect(() => {
    fetchTodaysAppointments();
  }, []);

  const fetchTodaysAppointments = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const hospitalId = localStorage.getItem('hospitalId');

      if (!hospitalId) {
        message.error('Hospital ID is missing.');
        return;
      }

      const response = await axios.get(`https://api.nillq.com/api/hospital/todays-appointments`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
        params: { hospitalId }
      });

      const appointmentsWithTime = response.data.map(app => ({
        ...app,
        time: `${moment(app.timeStart, 'HH:mm').format('hh:mm A')} - ${moment(app.timeEnd, 'HH:mm').format('hh:mm A')}`
      }));

      setAppointments(appointmentsWithTime);
    } catch (error) {
      console.error('Error fetching today\'s appointments:', error);
      message.error('Failed to fetch today\'s appointments.');
    }
  };

  return (
    <div className="p-6 space-y-6">
      <h3 className="text-3xl font-semibold mb-6">Hospital Dashboard</h3>

      <Card title="Today's Appointments" bordered={false} className="shadow-md">
        {appointments.length > 0 ? (
          <List
            itemLayout="vertical"
            dataSource={appointments}
            renderItem={(session) => (
              <List.Item>
                <List.Item.Meta
                  title={<span className="font-bold">{session.time}</span>}
                  description={
                    <div>
                      <p>Doctor: {session.doctorName}</p>
                      <p>Tokens Remaining: <span className="font-semibold">{session.remainingTokens}</span> / {session.numberOfTokens}</p>
                      <p>
                        Address: {session.location.address_line1}, {session.location.city}, {session.location.state}, {session.location.postal_code}, {session.location.country}
                      </p>
                    </div>
                  }
                />
              </List.Item>
            )}
          />
        ) : (
          <p>No appointments scheduled for today.</p>
        )}
      </Card>
    </div>
  );
};

export default DashboardHome;
