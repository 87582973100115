import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HospitalDashboard from './pages/HospitalDashboard';
import SignIn from './pages/SignIn';
import RequireAuth from './components/RequireAuth';
import AppointmentBooking from './components/AppointmentBooking'
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/signin" element={<SignIn />} />

        <Route element={<RequireAuth />}>
          <Route path="/booking" element={<AppointmentBooking />} />

          <Route path="/*" element={<HospitalDashboard />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default App;
