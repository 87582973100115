import React from 'react';
import Sidebar from '../components/Sidebar';
import Topbar from '../components/Topbar';
import DashboardHome from '../components/DashboardHome';
import AppointmentsManagement from '../components/AppointmentsManagement';
import PatientDetails from '../components/PatientDetails';
import ProfileManagement from '../components/ProfileManagement';
import DoctorManagement from '../components/DoctorManagement';
import { Routes, Route } from 'react-router-dom';

const HospitalDashboard = () => {
  return (
    <div className="flex h-screen bg-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col">
        <Topbar />
        <main className="flex-1 p-6">
          <Routes>
            <Route path="/dashboard-home" element={<DashboardHome />} />
            <Route path="/appointments-management" element={<AppointmentsManagement />} />
            {/* <Route path="/patient-details" element={<PatientDetails />} /> */}
              <Route path="/profile-management" element={<ProfileManagement />} />
            <Route path="/doctor-management" element={<DoctorManagement />} />
          </Routes>
        </main>
      </div>
    </div>
  );
}

export default HospitalDashboard;
