import React, { useState, useEffect, useMemo } from 'react';
import axios from 'axios';
import { message, Input, Spin, List } from 'antd';

const DoctorManagement = () => {
  const [associatedDoctors, setAssociatedDoctors] = useState([]);
  const [invitationsFromDoctors, setInvitationsFromDoctors] = useState([]);
  const [requestsToDoctors, setRequestsToDoctors] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchAssociatedDoctors();
    fetchInvitationsFromDoctors();
    fetchRequestsToDoctors();
  }, []);

  const fetchAssociatedDoctors = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const hospitalId = localStorage.getItem('hospitalId');
      const response = await axios.get(
        `https://api.nillq.com/api/hospital/associated-doctors?hospitalId=${hospitalId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setAssociatedDoctors(response.data);
    } catch (error) {
      console.error('Error fetching associated doctors:', error);
      message.error('Failed to fetch associated doctors.');
    }
  };

  const fetchInvitationsFromDoctors = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const hospitalId = localStorage.getItem('hospitalId');
      const response = await axios.get(
        `https://api.nillq.com/api/hospital/invitations-from-doctors?hospitalId=${hospitalId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setInvitationsFromDoctors(response.data);
    } catch (error) {
      console.error('Error fetching invitations from doctors:', error);
      message.error('Failed to fetch invitations from doctors.');
    }
  };

  const fetchRequestsToDoctors = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const hospitalId = localStorage.getItem('hospitalId');
      const response = await axios.get(
        `https://api.nillq.com/api/hospital/requests-to-doctors?hospitalId=${hospitalId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setRequestsToDoctors(response.data);
    } catch (error) {
      console.error('Error fetching requests to doctors:', error);
      message.error('Failed to fetch requests to doctors.');
    }
  };

  const handleSearch = async (query) => {
    setSearchQuery(query);
    if (!query.trim()) {
      setSearchResults([]);
      return;
    }
    setLoading(true);
    try {
      const token = localStorage.getItem('jwtToken');
      const response = await axios.get(
        `https://api.nillq.com/api/doctors/search?query=${query}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      setSearchResults(response.data);
    } catch (error) {
      console.error('Error searching doctors:', error);
      message.error('Failed to search doctors.');
    } finally {
      setLoading(false);
    }
  };

  const handleRequestToDoctor = async (doctorId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const hospitalId = localStorage.getItem('hospitalId');

      await axios.post(
        'https://api.nillq.com/api/hospital/request-to-doctor',
        { doctorId, hospitalId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      message.success('Request to doctor sent successfully');
      setSearchResults(searchResults.filter((doctor) => doctor._id !== doctorId));
      fetchRequestsToDoctors();
    } catch (error) {
      console.error('Error requesting to doctor:', error);
      message.error('Failed to send request to doctor.');
    }
  };

  const handleAcceptInvitation = async (doctorId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const hospitalId = localStorage.getItem('hospitalId');

      await axios.post(
        'https://api.nillq.com/api/hospital/approve-doctor-invitation',
        { doctorId, hospitalId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      message.success('Invitation accepted successfully');
      fetchAssociatedDoctors();
      fetchInvitationsFromDoctors();
    } catch (error) {
      console.error('Error accepting invitation:', error);
      message.error('Failed to accept invitation.');
    }
  };

  const handleRejectInvitation = async (doctorId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const hospitalId = localStorage.getItem('hospitalId');

      await axios.post(
        'https://api.nillq.com/api/hospital/reject-doctor-invitation',
        { doctorId, hospitalId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      message.success('Invitation rejected');
      setInvitationsFromDoctors(invitationsFromDoctors.filter((inv) => inv._id !== doctorId));
    } catch (error) {
      console.error('Error rejecting invitation:', error);
      message.error('Failed to reject invitation.');
    }
  };

  const handleCancelRequest = async (doctorId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const hospitalId = localStorage.getItem('hospitalId');

      await axios.post(
        'https://api.nillq.com/api/hospital/cancel-request-to-doctor',
        { doctorId, hospitalId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      message.success('Request to doctor cancelled');
      setRequestsToDoctors(requestsToDoctors.filter((req) => req._id !== doctorId));
    } catch (error) {
      console.error('Error cancelling request:', error);
      message.error('Failed to cancel request to doctor.');
    }
  };

  const handleRemoveDoctor = async (doctorId) => {
    try {
      const token = localStorage.getItem('jwtToken');
      const hospitalId = localStorage.getItem('hospitalId');

      await axios.post(
        'https://api.nillq.com/api/hospital/remove-doctor',
        { doctorId, hospitalId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      message.success('Doctor removed successfully');
      setAssociatedDoctors(associatedDoctors.filter((doctor) => doctor._id !== doctorId));
    } catch (error) {
      console.error('Error removing doctor:', error);
      message.error('Failed to remove doctor.');
    }
  };

  // Memoize associated doctors, invitations, and requests to prevent unnecessary re-renders
  const memoizedAssociatedDoctors = useMemo(() => associatedDoctors, [associatedDoctors]);
  const memoizedInvitationsFromDoctors = useMemo(() => invitationsFromDoctors, [invitationsFromDoctors]);
  const memoizedRequestsToDoctors = useMemo(() => requestsToDoctors, [requestsToDoctors]);

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold mb-6">Doctor Management</h3>

      {/* Search Doctors Section */}
      <div className="mb-6">
        <h4 className="text-lg font-semibold mb-4">Search Doctors</h4>
        <Input
          type="text"
          value={searchQuery}
          onChange={(e) => handleSearch(e.target.value)}
          placeholder="Search doctors by name"
          className="px-4 py-2 border rounded-md w-full"
        />
        {loading ? (
          <div className="flex justify-center items-center py-4">
            <Spin tip="Searching..." />
          </div>
        ) : searchResults.length > 0 ? (
          <List
            className="pt-4"
            itemLayout="horizontal"
            dataSource={searchResults}
            renderItem={(doctor) => (
              <List.Item
                actions={[
                  <button
                    onClick={() => handleRequestToDoctor(doctor._id)}
                    className="px-4 py-2 bg-blue-600 text-white font-semibold rounded-md shadow-md hover:bg-blue-700"
                  >
                    Request to Doctor
                  </button>,
                ]}
              >
                <List.Item.Meta
                  title={doctor.name}
                  description={doctor.specialization}
                />
              </List.Item>
            )}
          />
        ) : (
          <p className="text-gray-500 pt-4">No doctors found.</p>
        )}
      </div>

      {/* Associated Doctors Section */}
      <div className="mb-6">
        <h4 className="text-lg font-semibold mb-4">Associated Doctors</h4>
        {memoizedAssociatedDoctors.length > 0 ? (
          <List
            itemLayout="horizontal"
            dataSource={memoizedAssociatedDoctors}
            renderItem={(doctor) => (
              <List.Item
                actions={[
                  <button
                    onClick={() => handleRemoveDoctor(doctor._id)}
                    className="px-4 py-2 bg-red-600 text-white font-semibold rounded-md shadow-md hover:bg-red-700"
                  >
                    Remove
                  </button>,
                ]}
              >
                <List.Item.Meta
                  title={doctor.name}
                  description={doctor.specialization}
                />
              </List.Item>
            )}
          />
        ) : (
          <p>No associated doctors.</p>
        )}
      </div>

      {/* Invitations from Doctors Section */}
      <div className="mb-6">
        <h4 className="text-lg font-semibold mb-4">Invitations from Doctors</h4>
        {memoizedInvitationsFromDoctors.length > 0 ? (
          <List
            itemLayout="horizontal"
            dataSource={memoizedInvitationsFromDoctors}
            renderItem={(invitation) => (
              <List.Item
                actions={[
                  <button
                    onClick={() => handleAcceptInvitation(invitation._id)}
                    className="px-4 py-2 bg-green-600 text-white font-semibold rounded-md shadow-md hover:bg-green-700"
                  >
                    Accept
                  </button>,
                  <button
                    onClick={() => handleRejectInvitation(invitation._id)}
                    className="px-4 py-2 bg-red-600 text-white font-semibold rounded-md shadow-md hover:bg-red-700"
                  >
                    Reject
                  </button>,
                ]}
              >
                <List.Item.Meta
                  title={invitation.name}
                  description={invitation.specialization}
                />
              </List.Item>
            )}
          />
        ) : (
          <p>No pending invitations.</p>
        )}
      </div>

      {/* Requests to Join Doctors Section */}
      <div>
        <h4 className="text-lg font-semibold mb-4">Requests to Doctors</h4>
        {memoizedRequestsToDoctors.length > 0 ? (
          <List
            itemLayout="horizontal"
            dataSource={memoizedRequestsToDoctors}
            renderItem={(request) => (
              <List.Item
                actions={[
                  <button
                    onClick={() => handleCancelRequest(request._id)}
                    className="px-4 py-2 bg-red-600 text-white font-semibold rounded-md shadow-md hover:bg-red-700"
                  >
                    Cancel Request
                  </button>,
                ]}
              >
                <List.Item.Meta
                  title={request.name}
                  description={request.specialization}
                />
              </List.Item>
            )}
          />
        ) : (
          <p>No pending requests.</p>
        )}
      </div>
    </div>
  );
};

export default DoctorManagement;
