import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { message, Button, Row, Col, Form, Input } from 'antd';

const ProfileManagement = () => {
  const [profilePic, setProfilePic] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [description, setDescription] = useState('');
  const [address, setAddress] = useState({
    street: '',
    locality: '',
    city: '',
    state: '',
    pinCode: '',
  });

  useEffect(() => {
    fetchProfile();
  }, []);

  const fetchProfile = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const hospitalId = localStorage.getItem('hospitalId');
      const response = await axios.get(`https://api.nillq.com/api/hospital/profile?hospital_id=${hospitalId}`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const { name, email, phone, description, address, profilePic } = response.data;
      setName(name);
      setEmail(email);
      setPhone(phone);
      setDescription(description);
      setAddress(address);
      setProfilePic(profilePic);
    } catch (error) {
      console.error('Error fetching profile:', error);
    }
  };

  const handleSave = async () => {
    try {
      const token = localStorage.getItem('jwtToken');
      const hospitalId = localStorage.getItem('hospitalId');

      const data = {
        name: name,
        email: email,
        phone: phone,
        description: description,
        address: {
          street: address.street,
          locality: address.locality,
          city: address.city,
          state: address.state,
          pinCode: address.pinCode,
        },
      };

      await axios.put(`https://api.nillq.com/api/hospital/profile?hospital_id=${hospitalId}`, data, {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      });

      message.success('Profile updated successfully');
    } catch (error) {
      console.error('Error updating profile:', error);
      message.error('Failed to update profile.');
    }
  };

  return (
    <div className="max-w-4xl mx-auto p-6 bg-white rounded-lg shadow-md">
      <h3 className="text-2xl font-semibold mb-6">Profile Management</h3>
      <Row gutter={[16, 16]}>
        <Col xs={24} md={12} className="flex flex-col items-center">
          <div className="w-32 h-32 rounded-full overflow-hidden border-4 border-gray-200 mb-4">
            <img
              src={profilePic}
              alt="Profile"
              className="w-full h-full object-cover"
            />
          </div>
          {/* Commented out profilePic upload since server expects JSON */}
          {/* <input
            type="file"
            accept="image/*"
            onChange={handleProfilePicChange}
            className="mb-6"
          /> */}
        </Col>
        <Col xs={24} md={12}>
          <div className="space-y-4">
            <Form layout="vertical">
              <Form.Item label="Name">
                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Email">
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Phone">
                <Input
                  type="tel"
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                />
              </Form.Item>
              <Form.Item label="Description">
                <Input.TextArea
                  value={description}
                  onChange={(e) => setDescription(e.target.value)}
                  rows={4}
                />
              </Form.Item>
              <Form.Item label="Address">
                <Input
                  placeholder="Street"
                  value={address.street}
                  onChange={(e) => setAddress({ ...address, street: e.target.value })}
                  className="mb-2"
                />
                <Input
                  placeholder="Locality"
                  value={address.locality}
                  onChange={(e) => setAddress({ ...address, locality: e.target.value })}
                  className="mb-2"
                />
                <Input
                  placeholder="City"
                  value={address.city}
                  onChange={(e) => setAddress({ ...address, city: e.target.value })}
                  className="mb-2"
                />
                <Input
                  placeholder="State"
                  value={address.state}
                  onChange={(e) => setAddress({ ...address, state: e.target.value })}
                  className="mb-2"
                />
                <Input
                  placeholder="Pin Code"
                  value={address.pinCode}
                  onChange={(e) => setAddress({ ...address, pinCode: e.target.value })}
                />
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
      <div className="flex justify-end mt-6">
        <Button type="primary" onClick={handleSave}>
          Save Changes
        </Button>
      </div>
    </div>
  );
};

export default ProfileManagement;
